
@font-face {
    font-family: 'Toxigenesis';
    src: url('/fonts/toxigenesisbd.eot');
    src: url('/fonts/toxigenesisbd.eot') format('embedded-opentype'),
         url('/fonts/toxigenesisbd.woff2') format('woff2'),
         url('/fonts/toxigenesisbd.woff') format('woff'),
         url('/fonts/toxigenesisbd.ttf') format('truetype'),
         url('/fonts/toxigenesisbd.svg#toxigenesisbd') format('svg');
}
