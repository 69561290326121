@import 'webfonts.scss';

.lyricsContainer.dangerous {
    $card-transition-time: 0.15;
    $card-early-time: 0.15;
    $text-movement-time: 0.15;

    $notes: e, A, B, C, D, E;
    $note-fade-time: 0.15;

    overflow: hidden;

    font-family: 'Toxigenesis', sans-serif;
    font-size: 1rem;
    text-transform: uppercase;

    @function noteTimers() {
        $timers: "";
        @each $note in $notes {
            $timers: $timers + "--display-" + $note + " note-" + $note + ".start 0 note-" + $note + ".end " + $note-fade-time + " instant instantOut, ";
            $timers: $timers + "--fade-" + $note + " note-" + $note + ".end 0 note-" + $note + ".end " + $note-fade-time + " easeOut, ";
        }
        @return str-slice($timers, 1, -3);
    }

    --cue-timers: #{noteTimers()};

    .card {
        --card-timers: --fade-in start -#{$card-transition-time + $card-early-time} start -#{$card-early-time} linear, --fade-out end -#{$card-transition-time + $card-early-time} end -#{$card-early-time} linear;

        background-position: center;
        background-size: cover;
        z-index: -1;
        opacity: calc(var(--fade-in) - var(--fade-out));
        user-select: none;

        &.bgrepeat2x {
            background-size: 50vmax;
        }
        &.bgrepeat3x {
            background-size: 33.333vmax;
        }

        .contents {
            z-index: -4;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            transform: translateY(-50%);
            text-align: center;
        }

        .voice {
            display: block;
            overflow: visible;
            padding: 0;
            font-size: 0;

            --movement-direction: -1;

            &:first-child {
                --movement-direction: 1;
            }

            .voiceContents {
                display: inline-block;

                overflow: visible;
                padding: 0.1rem;
                margin: 1rem 0;
                font-size: 1rem;
                text-shadow: 0 4px 8px rgba(0, 0, 0, .36);
                //mix-blend-mode: difference;


                box-sizing: border-box;
                min-height: calc(var(--max-voice-height) * 1px);

                .word {
                    --word-timers: --fade-in start -#{$text-movement-time} start 0 linear, --shrink-in start -#{$text-movement-time} start 0 linear;

                    display: inline-block;
                    vertical-align: middle;

                    overflow: visible;

                    backface-visibility: hidden;

                    opacity: var(--fade-in);
                    transform-origin: 50% 50%;
                    transform: scale(calc(2 - var(--shrink-in)));
                }
            }
        }

        &.instrumental {
            --card-timers: --fade-in start -#{$card-transition-time + $card-early-time} start -#{$card-early-time} linear, --fade-out end -#{$card-transition-time + $card-early-time} end -#{$card-early-time} linear, --progress start 0 end 0 linear;

            opacity: calc(var(--fade-in) - var(--fade-out));

            &::before {
                display: block;
                content: ' ';

                position: absolute;
                top: 0;
                left: 0;
                height: 4px;
                width: calc(var(--progress) * 100%);

                background: white;
            }
        }

        &.big {
            .voice .voiceContents {
                font-size: 2rem;
            }
        }
    }

    .card.note-bar {
        position: absolute;
        height: 16.666%;

        background: orange;

        z-index: -5;

        $i: 0;
        @each $note in $notes {
            &.#{$note} {
                top: #{(5-$i) * 100% / 6};
                opacity: calc(var(--display-#{$note}) - var(--fade-#{$note}));
            }

            $i: $i + 1;
        }
        z-index: -2;
    }

    .card.invisible {
        display: none;
    }
}
